import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colorsV2 } from '../../../style/colors-v2';
import { Typography } from '../../DesignSystemV2/Typography';
import { AspectRatio } from '../../AspectRatio/AspectRatio';

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 4px;

  .title {
    &:hover {
      text-decoration: underline;
    }
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  background-color: ${colorsV2.black20};
  border-radius: 8px;
  overflow: hidden;

  .news-thumbnail {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LinkContainer = styled.a`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  align-items: flex-start;
  row-gap: 12px;
`;

export const DolNewsCard = props => {
  const { thumbnail, date, title, description, url, alt } = props;

  return (
    <LinkContainer href={url}>
      <AspectRatio className="image">
        <ImageContainer>
          <img
            className="news-thumbnail"
            alt={alt || title}
            fetchPriority="high"
            src={thumbnail}
          />
        </ImageContainer>
      </AspectRatio>
      <TextContainer>
        <Typography
          className="date"
          variant="regular/14-20"
          color={colorsV2.gray100}
        >
          {date}
        </Typography>
        <Typography
          className="title"
          variant="semi-bold/20-28"
          color={colorsV2.black100}
        >
          {title}
        </Typography>
        <Typography
          className="description"
          variant="regular/14-20"
          color={colorsV2.black80}
        >
          {description}
        </Typography>
      </TextContainer>
    </LinkContainer>
  );
};

DolNewsCard.propTypes = {
  url: PropTypes.string,
  thumbnail: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};
